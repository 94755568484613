import React from "react";
import "../assets/css/callto.css";
import done from "../assets/images/steps.png";
import online from "../assets/images/teacher-online.jpg";
function Callto() {
  return (
    <section id="call-to-ac">
      <div class="container" data-aos="zoom-out">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-center">
            <img className="img-fluid" src={online} height="300" />
          </div>

          <div class="col-lg-6 text-left">
            <h2 class="cta-title text-left p-2">Contact Admissions now</h2>
            <p class="cta-text">
              Speak to one of our education specialists who will help you choose
              the right course level and product package to meet your needs or child's
              specific needs. 
            </p>
      
              <p><img class="icon-user" src={done} /> Meet with an advisor</p>
              <p><img class="icon-user" src={done} />Discuss your needs</p>
              <p><img class="icon-user" src={done} />Get the right schooling package</p>
              <p><img class="icon-user" src={done} />Enrol with Gombwe at any time</p>
           
              
              <a class="cta-btn align-middle" href="#">
                 CHAT WITH US
              </a>
            
            
              
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Callto;
