import React from "react";
import "../assets/css/hero.css"
import done from "../assets/images/done.png";
import Image1 from "../assets/images/devbook-coverrr.png";



function Hero (){

    return (
<>
        <section id="hero" class="hero-section" style={{"height":"100vh","paddingTop":"10px"}}>
	    <div class="container">
		    <div class="row mt-10">
			    <div class="col-12 col-md-7 pt-5 mb-5 align-self-center">
				    <div class="promo pe-md-3 pe-lg-5">
					    <h1 class="headline mb-3 text-white" >
						Discover what Gombwe Online High School  has to offer.   
					    </h1>
					    {/*<div class="subheadline text-align-left text-white mb-4" style={{"textAlign":"left"}}>
						Unlimited access to Zimsec, Cambridge and Matric learning materials. Quizzes, assessments and exam oriented notes are available at an all inclusive subscription rate.						    
					    </div>*/}
					    <div id="chose">
						<p id="chose" style={{"textAlign":"left"}}>
                <p className="hero-t"><img  class="icon-user" src={done}/>Cambridge International</p>
                <p className="hero-t"><img  class="icon-user" src={done}/>Adult Matric</p>
                <p className="hero-t"><img  class="icon-user" src={done}/>ZIMSEC</p>
            </p>
						</div>
					    <div class="cta-holder row gx-md-3 gy-3 gy-md-0">
						    <div class="col-12 col-md-auto">
						        <a class="btn btn-primary w-100" href="https://forms.office.com/r/ACkVfNyCUj">SPEAK TO A CONSULTANT</a>
						    </div>
						    <div class="col-12 col-md-auto">
						        <a class="btn btn-secondary scrollto w-100" href="#benefits-section">ENROLL TODAY</a>
						    </div>
					    </div>
					    
					   
				    </div>
			    </div>
			    <div class="col-12 col-md-5 m align-self-center">
				    <div class="book-cover-holder">
					    <img class="img-fluid book-cover" src={Image1} alt="book cover"/>
					    
				    </div>
				    <div class="text-center mt-5"><a class="theme-link scrollto text-white" href="#reviews-section">See all the lessons we offer !</a></div>
			    </div>
		    </div>
	    </div>
    </section>


	</>
    )
}

export default Hero;