import React from "react"
import "../assets/css/home-portal.css"



function HomePortal(){
    return(
<div id="home-portal" class="container  ">


<div class="row">
    <div class="col-3 ">
    <aside>
  <p> Menu </p>
  <a href="javascript:void(0)">
    <i class="fa fa-user-o" aria-hidden="true"></i>
    ZIMSEC
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-laptop" aria-hidden="true"></i>
    CAMBRIDGE
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-clone" aria-hidden="true"></i>
    MATRIC
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-star-o" aria-hidden="true"></i>
    ENGLISH LANGUAGE
  </a>
  <a href="">
    Others
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-trash-o" aria-hidden="true"></i>
    Downloads
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-trash-o" aria-hidden="true"></i>
    Emergency Contacts
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-trash-o" aria-hidden="true"></i>
    Gombwe Calendar
  </a>
  <a href="javascript:void(0)">
    <i class="fa fa-trash-o" aria-hidden="true"></i>
    Updates & News
  </a>
</aside>
    </div>
    <div className="col-lg-9" >
        <div className="row ">
    <div class="card col-lg-2 mr-5" data-label="Coming Soon">
        <div class="card-container">
            <h4>Coming Soon</h4>
        </div>
    </div>
    <div class="card col-2 mr-5" data-label="Coming Soon">
        <div class="card-container">
            <h4>Coming Soon</h4>
        </div>
    </div>
    <div class="card col-2 mr-5" data-label="Coming Soon">
        <div class="card-container">
            <h4>Coming Soon</h4>
        </div>
    </div>
    <div class="card col-2 mr-5" data-label="Coming Soon">
        <div class="card-container">
            <h4>Coming Soon</h4>
        </div>
    </div>
   </div>
</div>
 
</div>
   {/* <div className="row">
        <div className="col-lg-3 ">
        <div class="card shaodw-lg  card-1">
        <div class="card-header pt-3 pb-0 ml-auto border-0 ">
        </div>
        <div class="card-body  d-flex pt-0">
            <div class="row no-gutters  mx-auto justify-content-start flex-sm-row flex-column">
                <div class="col-md-4  text-center"><img class="irc_mi img-fluid mr-0" src="https://cdn4.iconfinder.com/data/icons/logistics-delivery-2-5/64/137-512.png"  width="150" height="150"/></div>
                <div class="col-md-6 ">
                    <div class="card border-0 ">
                        <div class="card-body">
                            <h5 class="card-title "><b>This is fake data.</b></h5>
                            <button type="button" class="btn btn-primary btn-round-lg btn-lg"> Import my data </button>
                        </div>
                    </div>
                </div>
           </div>
        </div> 
    </div>
        </div>
        <div className="col-lg-9">
    <div class="card shaodw-lg  card-1">
        <div class="card-header pt-3 pb-0 ml-auto border-0 ">
        </div>
        <div class="card-body  d-flex pt-0">
            <div class="row no-gutters  mx-auto justify-content-start flex-sm-row flex-column">
                <div class="col-md-4  text-center"><img class="irc_mi img-fluid mr-0" src="https://cdn4.iconfinder.com/data/icons/logistics-delivery-2-5/64/137-512.png"  width="150" height="150"/></div>
                <div class="col-md-6 ">
                    <div class="card border-0 ">
                        <div class="card-body">
                            <h5 class="card-title "><b>This is fake data.</b></h5>
                            <p class="card-text "><p>Lorem ipsum dolor srt amet. can tetur adipiscing elt, sed do.</p></p>
                            <button type="button" class="btn btn-primary btn-round-lg btn-lg"> Import my data </button>
                        </div>
                    </div>
                </div>
           </div>
        </div> 
    </div>
</div>
</div>*/}
</div>
    )
}

export default HomePortal; 