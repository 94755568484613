import React from "react";
import "../assets/css/about-home.css"



function AboutHome (){

    return (

  
  <div id="aboutus" class="feat bg-gray pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="section-head col-sm-12">
            <h4><span>Why Gombwe Online High School?</span></h4>
            <p class="cta-text">      You don't need to quit your job or transport to learn with Gombwe Online High School. We are online platform assisting school drops , school students ,and anyone who is looking their education, languages and skills. Wether you drop about in grade 7 , we assit you furthering your education wether in Zimsec Exams , <b>CAMBRIDGE</b> General Certificate of Education Ordinary Level <b>(GCE O-Level & A-Level)</b>, Zimbabwe School Examinations Council <b>(ZIMSEC) O-Level</b> and <b>A-Level</b>, National Senior Certificate (NSC) referred as<b> MATRIC</b> ,english language.Learn from the comfort of your place .
</p>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_one"><i class="fa fa-globe"></i></span>
              <h3 className="item-heading"> Online Learning Content</h3>
              <p class="cta-text">Access detailed course outlines, multi-sensory videos and interactive lessons available 24/7 on mobile, laptop, tablet and PC.</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_two"><i class="fa fa-anchor"></i></span>
              <h3 className="item-heading">Certified Teachers & Tutors</h3>
              <p class="cta-text">
Hand-picked teachers from across the globe to ensure that students get the best education and support.</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_three"><i class="fa fa-hourglass-half"></i></span>
              <h3 className="item-heading">Live & Recorded Lessons</h3>
              <p class="cta-text">
Gombwe students get access to structured lessons in an online classroom environment or an on demand video library to review their course work.</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_four"><i class="fa fa-database"></i></span>
              <h3 className="item-heading">Student Performance Tracker</h3>
              <p class="cta-text">
Easily track improvement across each of your courses and get marked assignments with teacher feedback.</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_five"><i class="fa fa-upload"></i></span>
              <h3 className="item-heading">Flexible Schooling Options</h3>
              <p class="cta-text">
Parents can combine curriculums, packages and different schooling levels to provide a personalised education for their children.</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="item"> <span class="icon feature_box_col_six"><i class="fa fa-camera"></i></span>
              <h3 className="item-heading">Recognised Qualifications</h3>
              <p class="cta-text">
All our courses and qualifications prepare students for further studies locally and abroad.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  


	
    )
}

export default AboutHome;