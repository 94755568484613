import React from "react"

import don from "../assets/images/lear-home.jpg";


function Follow(){

    return (
        <section class="about-us py-5 " id="about-us">
        <div class="container mt-5">
        <div class="row">
            <div class="col-md-7">
               <h2>The perfect online platform to Learn from home.Start learning today !</h2>
                <hr/>
                <p>               Our Support Coaches are our superheroes — they hold learners accountable through weekly group check ins and a learner priority system based on at-risk behaviours.Where learners fail their weekly assessments or get stuck, they have access to weekly live streams with Learning Facilitators and a dedicated academic discussion forum. It’s a very structured approach, but with all the flexibility in between.</p>

                <button type="button" class="btn btn-primary ">REGISTER TODAY</button>
    
            </div>
            <div class="col-md-5 p-5">
                <img className="img-fluid" src={don} height="300" alt=""/>
            </div>
        </div>
    </div>
    
    </section>
    )
}

export default Follow;