import React from "react"
import logo from "../logo.jpeg"

function NavBar()
{
    return(

        <>
   
        <nav class="navbar navbar-expand-lg  static-top text-white" style={{backgroundColor:"#0022a7"}}>
          <div class="container nav-back">
            <a class="navbar-brand" href="#">
             
              <h3 className="text-white">Gombwe</h3>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto text-white">
                <li class="nav-item mt-2">
                  <a class="nav-link active text-white" aria-current="page" href="/">Home</a>
                </li>
                
                <li class="nav-item mt-2">
                  <a class="nav-link active text-white" aria-current="page" href="/sections">About Gombwe</a>
                </li>
                <li class="nav-item mt-2">
                  <a class="nav-link active text-white" aria-current="page" href="/pricing">Contact Us</a>
                </li>
                <li class="nav-item">
                <a class="btn btn-secondary scrollto w-100" href="https://forms.office.com/r/ACkVfNyCUj">Enroll</a>
                </li>

              
                
              </ul>
            </div>
          </div>
        </nav>
        
        
        
        </>

    )
};

export default NavBar;