import React from "react";
import "../assets/css/footer.css"
import logo from "../logo.jpeg"

function Footer (){
    return(
<div id= "footer"  class="container-fluid justify-content-center p-0 mx-0 footer">
   
    <footer>
         <div class="card border-0">
             <div class="card-body px-5">
                <div class="row  justify-content-around mb-0 pt-5 ">
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12   my-auto"><ul class="list-unstyled mt-md-3 mt-5"><li> <p class="mb-4">Contact us about sponsorship </p></li><li> <div class="card card-1 border-0"> <div class="card-body p-0 pl-3"><h6 class="mb-3"><b>Ankita Mehra</b></h6> <small>scholarship@gombwe.com</small><br/> <small>991094697</small>   </div></div> </li></ul> </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12   my-auto"><ul class="list-unstyled mt-md-3 mt-5"><li> <p class="mb-4">Quries about registration contact</p></li><li> <div class="card card-1 border-0"> <div class="card-body p-0 pl-3"><h6 class="mb-3"><b>Maxwell Chimedza</b></h6> <small>drmaxxonlineschool@gombwe.com</small><br/> <small>801094697</small>   </div></div> </li></ul> </div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12   my-auto"><ul class="list-unstyled mt-md-3 mt-5"><li> <p class="mb-4">Quries about registration contact</p></li><li> <div class="card card-1 border-0"> <div class="card-body p-0 pl-3"><h6 class="mb-3"><b>Madam Kwari</b></h6> <small>m.kwari@gombwe.com</small><br/> <small>801094697</small>   </div></div> </li></ul> </div>
                    <div class="col-xl-auto col-md-6 col-sm-6 col-12   my-auto"><ul class="list-unstyled mt-md-3 mt-5"><li> <p class="mb-4">For technical issue contact</p></li><li> <div class="card card-1 border-0"> <div class="card-body p-0 pl-3"><h6 class="mb-3"><b>Lawrence Muzondo</b></h6> <small>lawrence.muzondo@gombwe.com</small><br/> <small>771094697</small>   </div></div> </li></ul> </div>
                </div>
                <div class="row justify-content-around mb-0 py-5 ">
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12  pt-4"><ul class="list-unstyled"><li class="mt-md-0 mt-4">For Companies</li><li>Become a Sponsor</li><li>Download  brochure</li></ul></div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12  pt-4"><ul class="list-unstyled"><li class="mt-md-0 mt-4">For Companies</li><li>Become a Sponsor</li><li>Download  brochure</li></ul></div>
                    <div class="col-xl-3 col-md-6 col-sm-6 col-12  pt-4"><ul class="list-unstyled"><li class="mt-md-0 mt-4">Our  initiatiives</li><li>Enablers</li><li>Changemakers</li></ul></div>
                    <div class="col-xl-auto col-md-6 col-sm-6 co-12  pt-4"><ul class="list-unstyled"><li class="mt-md-0 mt-4">We are social</li><li><a href="#" class="fa fa-facebook"></a><a href="#" class="fa fa-twitter"></a><a href="#" class="fa fa-instagram"></a><a href="#" class="fa fa-linkedin"></a></li></ul></div>
                </div>
             </div>
         </div>
        <div class="row justify-content-center px-3 py-3 pt-5"><div class="col-lg-6 text-left"> <span> <img class="footer img-fluid" src={logo} size="40"/> </span><h6 class="mb-0"> <span><b>Gombwe Online<br/> High School  </b> </span> </h6></div><div class="col-lg-6 text-right"><h6 class="mb-0"> <span>  </span> <b>Engineered & Empowered<a href="http//akiltech.co"> Akiltech Inc.</a> </b>   </h6></div></div>
    </footer>
    <hr class="colored" />
</div>
    )
}

export default Footer;