import React from "react";
import Sections from "./Sections";
import Hero from "./Hero";
import Follow from "./Follow";

import Newsletter from "./Newsletter";


function Landing (){

    return (
<>
       
<Hero/>

<Sections/>
<Follow/>
<Newsletter/>



	</>
    )
}

export default Landing;