
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Landing from './components/Landing';
import Sections from './components/Sections';
import HomePortal from './components/HomePortal';

function App() {
  let component 
  switch(window.location.pathname){
    case "/" :
      component= <Landing/>
      break
case "/sections" :
  component = <Sections/>
  break
  
  case "/gombwe-home-portal" :
  component = <HomePortal/>
  }
  
  return (
    <>
      <NavBar/>
      
      {component}
      <Footer/>
    </>
  );
}

export default App;
