import React from "react";
import "../assets/css/newsletter.css";

import online from "../assets/images/teacher-online.jpg";
function Newsletter() {
  return (
    <section class="newsletter">
<div class="container">
<div class="row">
<div class="col-sm-12">
	<div class="content">
		<h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
	<div class="input-group">
         <input type="email" class="form-control" placeholder="Enter your email"/>
         <span class="input-group-btn">
         <button class="btn" type="submit">Subscribe Now</button>
         </span>
          </div>
	</div>
</div>
</div>
</div>
</section>
  );
}

export default Newsletter;
