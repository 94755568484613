import React from "react"
import "../assets/css/sections.css";
import Social from "./social-connect";
import SaveMoney from "../assets/images/save-money-by-gombwe.png"
import Learn from "../assets/images/enroll-at-gombwe.png"
import Flexible from "../assets/images/icons8-google-classroom-64.png"
import AboutHome from "./AboutHome";
import Callto from "./Callto";
import CallA from "./CallA";



function Sections(){
    return (
        <div className="gombwe-section">
  <section
    id="benefits-section"
    className="benefits-section theme-bg-light-gradient py-5"
  >
    <div className="container py-5">
      <h2 className="section-heading text-center mb-3">
      Join thousands of learners who are achieving their goals with Gombwe
      </h2>
      <div className="section-intro single-col-max mx-auto text-center mb-5">
      Get unlimited access to over 90% of ZIMSEC, Adult Matric Programme and Cambridge subjects taught by top instructors on Gombwe ;an online  school platform serving all     </div>
      <div className="row text-center">
        <div className="item col-12 col-md-6 col-lg-4">
          <div className="item-inner p-3 p-lg-4">
            <div className="item-header mb-3">
              <div className="item-icon">
              <img src={Flexible} color="black" alt="Learn with Gombwe"/>              </div>
              <h3 className="item-heading">
              Flexible learning
              </h3>
            </div>
            {/*//item-heading*/}
            <div className="item-desc">
            Learn at your own pace, move between multiple courses,the provision of text books,question and answer booklets and all relevant study packs, relevant notes upon one's request and in line with the group lessons' timetable.
            </div>
            {/*//item-desc*/}
          </div>
          {/*//item-inner*/}
        </div>
        {/*//item*/}
        <div className="item col-12 col-md-6 col-lg-4">
          <div className="item-inner p-3 p-lg-4">
            <div className="item-header mb-3">
              <div className="item-icon">
                <img src={Learn} alt="Learn with Gombwe"/>
              </div>
              <h3 className="item-heading">
              Learn anything
              </h3>
            </div>
            {/*//item-heading*/}
            <div className="item-desc">
            Explore the trendiest topics and advanced your knowledge. We also offer the teaching of question interpreting, addressing individual subject problems in the inbox and eloquently presented audio lessons are also there.            </div>
            {/*//item-desc*/}
          </div>
          {/*//item-inner*/}
        </div>
        {/*//item*/}
        <div className="item col-12 col-md-6 col-lg-4">
          <div className="item-inner p-3 p-lg-4">
            <div className="item-header mb-3">
              <div className="item-icon">
              <img src={SaveMoney} alt="Learn with Gombwe and save money"/>              </div>
              <h3 className="item-heading">
              Save money
              </h3>
            </div>
            {/*//item-heading*/}
            <div className="item-desc">
            Spend less money for your learning this year. There are low or no stationery costs since notes and audio lessons are provided online.We cover key examinable areas in preparation of June and October final examinations
            </div>
            {/*//item-desc*/}
          </div>
          {/*//item-inner*/}
        </div>
        {/*//item*/}

        {/*//item*/}
        
        {/*//item*/}
        
        {/*//item*/}
      </div>
      {/*//row*/}
    </div>
    {/*//container*/}
  </section>
  {/*//benefits-section*/}
  
  <CallA/>
  <Social/>
  <div></div>
  <Callto/>
  <AboutHome/>
  

  


  
  {/*//form-section*/}
</div>

    )
};


export default Sections;