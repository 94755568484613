import React from "react";
import "../assets/css/social-connect.css";

function Social() {
  return (
    <div id="social" class="social-connect container px-5 py-5 mx-auto">
      
      <div class="feat bg-gray pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="section-head col-sm-12">
              <h4>
                <span>See what you can learn with Gombwe</span>
              </h4>
              <p>
                {" "}
                Forget classrooms, here we focus on online learning.Enroll for
                affordable, high-quality education from anywhere in the world
              </p>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_one">
                  <i class="fa fa-globe"></i>
                </span>
                <h3 class="pb-3 ">Cambridge International</h3>
                <p class="sm-text">
                  Get the most affordable internationally recognised
                  qualification from one of the most respected institutions on
                  the planet and open the door to your future
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_two">
                  <i class="fa fa-anchor"></i>
                </span>
                <h3 class="pb-3 ">Adult Matric</h3>
                <p class="sm-text">
                  Join our 18 months long Adult Matric Programme and work
                  towards yours CAPS aligned Amended Senior Certificate
                  (ASC).Its never too late to enroll ,success knows no age.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="item">
                {" "}
                <span class="icon feature_box_col_three">
                  <i class="fa fa-hourglass-half"></i>
                </span>
                <h3 class="pb-3 ">ZIMSEC</h3>
                <p class="sm-text">
                  Access to our platform , a great place to learn where learners
                  have full access to notes, videos, practise assignments,
                  quizzes,past exam materials and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Social;
