import React from "react";
import "../assets/css/callAction.css";
import done from "../assets/images/done.png";
import don from "../assets/images/online-gombwe.jpg";
function CallA() {
  return (
    <section id="call-to-ac1">
      <div class="container" data-aos="zoom-out">
        <div class="row">
        <div className="col-lg-5 col-md-6">
<img className="img-fluid" src={don} height="300"/>
          </div>
          <div class="col-lg-7 col-md-6 text-left text-lg-start">
            <h3 class="cta-title">We do schooling differently.</h3>
            {/*    <p class="cta-text">Applications for 2024 are open and space is limited. Our Online Campus is at the centre of it all. Every day, your child logs in and sees their weekly work plan; a live, interactive view of everything they need to complete in a week, for every subject. It’s the most comprehensive timetable they’ll ever need!</p>
             */}{" "}
            
              <p>
                <img class="icon-user" src={done} />
                No classrooms.
              </p>
              <p>
                <img class="icon-user" src={done} />
                No  wasted time.
              </p>
              <p>
                <img class="icon-user" src={done} />
                Here the focus is on learning.
              </p>
            
            <div class=" cta-btn-container text-center">
              <a class="cta-btn align-middle" href="#">
                ENROLL TODAY
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

export default CallA;
